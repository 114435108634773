<template>
    <div>
        <!-- Main Start -->
        <main class="main">
            <!-- Breadcrumb Start -->
            <div class="breadcrumb-wrap">
                <div class="banner">
                    <img class="bg-img bg-top" src="/assets/images/bg/bg-header-001.jpg" alt="banner" />

                    <div class="container-lg">
                        <div class="breadcrumb-box">
                            <div class="heading-box">
                                <h1>Mi Carrito</h1>
                            </div>
                            <ol class="breadcrumb">
                                <li><a href="/">Inicio</a></li>
                                <li>
                                    <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                                </li>
                                <li><a href="/shop">Tienda</a></li>
                                <li>
                                    <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                                </li>
                                <li class="current"><a href="javascript:void(0)">Carrito</a></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Breadcrumb End -->

            <!-- Cart Section Start -->
            <section class="section-b-space card-page" v-if="!loader">
                <div class="container-lg">
                    <div class="bg-danger p-2 text-white rounded shadow mb-2" style="width: 100%; display: none;">
                        Para que todos puedan aprovechar nuestras ofertas CachaBLACK; cada cliente puede comprar una
                        unidad de cada producto disponible en la promoción.
                    </div>
                    <div class="row g-3 g-md-4 cart">
                        <div class="col-md-7 col-lg-9">
                            <div class="cart-wrap">
                                <div class="items-list">
                                    <table class="table cart-table m-md-0">
                                        <thead>
                                            <tr>
                                                <th class="d-none d-sm-table-cell">PRODUCTO</th>
                                                <th class="d-none d-sm-table-cell">PRECIO</th>
                                                <th class="d-none d-lg-table-cell">CANTIDAD</th>
                                                <th class="d-none d-xl-table-cell">TOTAL</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <!-- v-if="loadingSucursal" -->
                                        <tbody>

                                            <template v-for="(CART, index) in CARTS" :key="index">
                                                <tr>
                                                    <td>
                                                        <div class="product-detail">
                                                            <img class="pr-img" v-bind:src="CART.imagen" alt="image" />
                                                            <div class="details">
                                                                <h4 class="title-color font-default2">
                                                                    <router-link :to="'/product/' + CART.slug">
                                                                        {{ CART.title }}
                                                                    </router-link>
                                                                </h4>
                                                                <template v-if="CART.is_pc_build">
                                                                    <b style="color: #3b73f1;">PC Builder</b>
                                                                </template>
                                                                <template v-if="CART.kit">
                                                                    <b style="color: #3b73f1;"> <small>KIT: {{
                                                                        CART.kit.name }}</small> </b>
                                                                </template>
                                                                <!-- <span class="sold-by">Sold By: <span>Roger Group</span> </span> -->
                                                                <!-- <span class="size gap-2 d-flex">Size : <span>M</span> </span> -->
                                                                <!-- isNotStockProduct(CART) -->
                                                                <small class="text-danger"
                                                                    v-if="CART.is_not_stock_product">
                                                                    {{ isNotStockProduct(CART) }}
                                                                </small>
                                                                <span class="size gap-2 d-flex d-sm-none">Total :
                                                                    <span>$ {{ CART.total }} </span> </span>
                                                                <div class="d-block d-sm-none"
                                                                    v-if="CART.code_cupon || CART.code_discount || CART.code_offer">

                                                                    <!-- <small class="sold-by" v-if="CART.code_offer">Oferta <span>{{ CART.code_offer }}</span> </small> -->
                                                                    <!-- <br> -->
                                                                    <!-- <small class="sold-by" v-if="CART.type_discount == 1">Descuento : <span>- {{ CART.discount }} %</span> </small>
                                                                <br v-if="CART.type_discount == 1">
                                                                <small class="sold-by" v-if="CART.type_discount == 2">Descuento : <span>- $ {{ CART.discount }} </span> </small>
                                                                <br v-if="CART.type_discount == 2"> -->
                                                                    <small class="sold-by">Antes : <span> $ {{
                                                                        CART.price_unit }} </span> </small>
                                                                    <!-- <br>
                                                                <small class="sold-by" v-if="CART.code_cupon && !CART.code_discount && !CART.code_offer">Cupon: <span>{{ CART.code_cupon }}</span> </small> -->
                                                                    <!-- <small class="sold-by" v-if="CART.code_discount">Descuento: <span>{{ CART.code_discount }}</span> </small> -->
                                                                </div>

                                                                <div class="plus-minus mt-2 d-inline-flex d-lg-none">
                                                                    <a href="javascript:void(0)"
                                                                        @click="minusCart(CART)"> <i class="sub"
                                                                            data-feather="minus"></i> </a>
                                                                    <input type="number" :value="CART.quantity" min="1"
                                                                        max="10" />
                                                                    <a href="javascript:void(0)"
                                                                        @click="plusCart(CART)"> <i class="add"
                                                                            data-feather="plus"></i> </a>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="price d-none d-sm-table-cell" style="text-wrap: nowrap;">
                                                        <template
                                                            v-if="(CART.code_cupon || CART.code_discount || CART.code_offer) && CART.discount > 0 && !CART.is_kit && !CART.is_pc_build">
                                                            <b class="color-oferta-cart">Oferta </b>
                                                        </template>
                                                        ${{ Number(CART.subtotal).toFixed(2) }}

                                                        <template
                                                            v-if="(CART.code_cupon || CART.code_discount || CART.code_offer) && CART.discount > 0 && !CART.is_kit && !CART.is_pc_build">

                                                            <!-- <small class="sold-by" v-if="CART.code_offer">Oferta <span>{{ CART.code_offer }}</span> </small> -->
                                                            <br>
                                                            <!-- <small class="sold-by" v-if="CART.type_discount == 1">Descuento <span>- {{ CART.discount }} %</span> </small>
                                                        <br v-if="CART.type_discount == 1">
                                                        <small class="sold-by" v-if="CART.type_discount == 2">Descuento <span>- $ {{ CART.discount }} </span> </small>
                                                        <br v-if="CART.type_discount == 2"> -->
                                                            <small class="sold-by"> Antes <del><span> ${{
                                                                CART.price_unit }} </span></del> </small>
                                                            <!-- <br>
                                                        <small class="sold-by" v-if="CART.code_cupon && !CART.code_discount && !CART.code_offer">Cupon: <span>{{ CART.code_cupon }}</span> </small> -->
                                                            <!-- <small class="sold-by" v-if="CART.code_discount">Descuento: <span>{{ CART.code_discount }}</span> </small> -->
                                                        </template>
                                                    </td>
                                                    <td class="d-none d-lg-table-cell">
                                                        <div class="plus-minus">
                                                            <a href="javascript:void(0)" @click="minusCart(CART)"> <i
                                                                    class="sub" data-feather="minus"></i> </a>
                                                            <input
                                                                :disabled="(CART.code_cupon || CART.code_discount || CART.code_offer) && CART.discount > 0 && !CART.is_kit && !CART.is_pc_build"
                                                                pe="number" :value="CART.quantity"
                                                                @keyup="processQuantity(CART, $event)"
                                                                @keyup.enter="processQuantity(CART, $event)" min="1"
                                                                max="10" />
                                                            <a href="javascript:void(0)" @click="plusCart(CART)"> <i
                                                                    class="add" data-feather="plus"></i> </a>
                                                        </div>
                                                    </td>
                                                    <td class="total d-none d-xl-table-cell" style="text-wrap:nowrap;">$
                                                        {{ Number(CART.total).toFixed(2) }}
                                                        <template
                                                            v-if="CART.discount > 0 && !CART.is_kit && !CART.is_pc_build">
                                                            <br>
                                                            <b class="color-oferta-cart">Ahorro </b>
                                                            ${{ getDescountC(CART) }}
                                                        </template>
                                                    </td>
                                                    <td>
                                                        <a v-if="CART.is_pc_build || CART.is_kit" data-bs-toggle="modal"
                                                            data-bs-target="#viewCartDelete" class="remove-cart"
                                                            @click="removePcBuild(CART)"
                                                            style="color: white;background: #10121b;">
                                                            <i data-feather="x"></i>
                                                        </a>

                                                        <button v-if="!CART.is_pc_build && !CART.is_kit"
                                                            class="remove-cart" @click="removeCart(CART)"
                                                            style="color: white;background: #10121b;"><i
                                                                data-feather="x"></i></button>
                                                    </td>
                                                </tr>
                                            </template>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-5 col-lg-3">
                            <div class="summery-wrap">
                                <div class="coupon-box">
                                    <h5 class="cart-title"><i data-feather="tag"></i> Cupón</h5>
                                    <div class="text-wrap">
                                        <!-- <h4><i data-feather="tag"></i> Apply Coupon</h4> -->
                                        <div class="input-flex2" bis_skin_checked="1">
                                            <input class="form-control3 coupon-input" type="text"
                                                placeholder="Cupón....." name="coupon" v-model="code_cupon"
                                                v-on:keyup.enter="applyCupon" />
                                            <button @click="applyCupon()" class="btn-green">Aplicar</button>
                                        </div>
                                    </div>

                                    <span v-if="groups_coupons.length > 0">Cupones Aplicados: </span>
                                    <template v-for="(groups_coup, index) in groups_coupons" :key="index">
                                        <p class="content-color font-md mb-0"> {{ groups_coup }} <a
                                                href="javascript:void(0)" @click="removeCoupon(groups_coup)"
                                                class="theme-color">X</a></p>
                                    </template>

                                </div>
                                <div class="cart-wrap grand-total-wrap">
                                    <div>
                                        <div class="order-summery-box">
                                            <h5 class="cart-title">Resumen: </h5>
                                            <ul class="order-summery">
                                                <li>
                                                    <span>Subtotal</span>
                                                    <span>$ {{ SUB_TOTAL_SUM.toFixed(2) }} </span>
                                                </li>

                                                <li>
                                                    <span>Descuento</span>
                                                    <span>$ {{ DESCUENTO_TOTAL_SUM.toFixed(2) }} </span>
                                                </li>

                                                <li class="pb-0">
                                                    <span>Total</span>
                                                    <span>$ {{ TOTAL_SUM.toFixed(2) }}</span>
                                                </li>
                                            </ul>
                                            <div class="row g-3 mt-2">
                                                <div class="col-6 col-md-12">

                                                    <router-link to="/checkout" class="btn-solid checkout-btn"
                                                        v-if="Number(TOTAL_SUM) > 0">Pagar <i
                                                            class="arrow"></i></router-link>

                                                </div>
                                                <div class="col-6 col-md-12">
                                                    <a href="/shop"
                                                        class="btn-outline w-100 justify-content-center checkout-btn">
                                                        Volver a
                                                        Tienda </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section-b-space card-page" v-if="loader">
                <div class="loader_div">
                    <div class="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </section>
            <!-- Cart Section End -->
        </main>
        <!-- Main End -->
        <ModalDeleteCart></ModalDeleteCart>
    </div>
</template>
<script>
import store from '../../plugins/store'
import myApi from '../../plugins/axios'
import CartResource from '../../resource/cart'
import { useMeta } from 'vue-meta'
import ModalDeleteCart from './modal/ModalDeleteCart.vue'

export default {
    name: 'CartShop',
    data() {
        return {
            CARTS: [],
            TOTAL_SUM: 0,
            code_cupon: null,
            groups_coupons: [],
            sin_stock: null,
            products_process_sucursals: [],
            loadingSucursal: true,
            SUB_TOTAL_SUM: 0,
            DESCUENTO_TOTAL_SUM: 0,
            CART_SELECTED: null,
            loader: true
        }
    },
    components: {
        ModalDeleteCart,
    },
    methods: {
        getDescountC(CART) {
            if (CART.discount > 0) {
                return (CART.price_unit - CART.subtotal).toFixed(2);
            }
            return 0;
        },
        processQuantity(CART, event) {
            if (event.target.value < 1) {
                this.$notify({
                    title: 'Cantidad de producto no válida',
                    // title: "CARRITO DE COMPRA",
                    // text: `AL PRODUCTO '${CART.title}' NO SE LE PUEDE DISMINUIR MENOS DE 1!`,
                    type: 'error',
                    duration: 4500,
                });
                return;
            }
            console.log(event.target.value);
            let INDEX = this.CARTS.findIndex(product => product.uniqd == CART.uniqd);
            if (INDEX != -1) {
                CartResource.addDinamic(this.CARTS, this.CARTS[INDEX], this.emitter, this.$notify, this.$cookies, event.target.value)
            }
        },
        minusCart(CART) {
            // if((CART.code_cupon || CART.code_discount || CART.code_offer) && CART.discount > 0 && !CART.is_kit && !CART.is_pc_build) return
            if (CART.quantity <= 1) {
                this.$notify({
                    title: 'Cantidad de producto no válida',//`AL PRODUCTO '${CART.title}' NO SE LE PUEDE DISMINUIR MAS!`,
                    // text: `AL PRODUCTO '${CART.title}' NO SE LE PUEDE DISMINUIR MAS!`,
                    type: 'error',
                    duration: 4500,
                });
                return;
            }
            let INDEX = this.CARTS.findIndex(product => product.uniqd == CART.uniqd);

            if (INDEX != -1) {
                // this.CARTS[INDEX].quantity = this.CARTS[INDEX].quantity - 1;
                // this.CARTS[INDEX].total =  this.CARTS[INDEX].subtotal * this.CARTS[INDEX].quantity;
                CartResource.add(this.CARTS, this.CARTS[INDEX], this.emitter, this.$notify, this.$cookies, -1)
            }
            // this.$cookies.set('carts',this.CARTS);
            // this.emitter.emit('cart-shop', this.CARTS);
        },
        plusCart(CART) {
            // if((CART.code_cupon || CART.code_discount || CART.code_offer) && CART.discount > 0 && !CART.is_kit && !CART.is_pc_build) return
            let INDEX = this.CARTS.findIndex(product => product.uniqd == CART.uniqd);
            if (INDEX != -1) {
                // this.CARTS[INDEX].quantity = this.CARTS[INDEX].quantity + 1;
                // this.CARTS[INDEX].total =  this.CARTS[INDEX].subtotal * this.CARTS[INDEX].quantity;
                CartResource.add(this.CARTS, this.CARTS[INDEX], this.emitter, this.$notify, this.$cookies, 1)
            }
            // this.$cookies.set('carts',this.CARTS);
            // this.emitter.emit('cart-shop', this.CARTS);
        },
        removePcBuild(CART) {
            this.CART_SELECTED = CART;
            CART.is_cart_shop = true;
            this.emitter.emit("open-delete-cart", CART);
        },
        removeCart(CART) {
            // let CARTS = this.$cookies.get("carts") ? this.$cookies.get("carts") : [];
            let INDEX = this.CARTS.findIndex(product => product.uniqd == CART.uniqd);

            if (!store.getters.loggedIn) {

                if ((CART.is_pc_build || CART.is_kit) && CART.discount > 0) {
                    let CART_FORMAT = this.CARTS;
                    this.CARTS = [];
                    let index = 0;
                    for (const CARTR of CART_FORMAT) {
                        CARTR.type_discount = null;
                        CARTR.discount = 0;
                        CARTR.discount_old = null;
                        CARTR.type_discount_old = null;
                        CARTR.code_offer = null;
                        CARTR.subtotal = CARTR.price_unit;
                        CARTR.total = Number((CARTR.price_unit * CARTR.quantity).toFixed(2));
                        if (INDEX != index) {
                            this.CARTS.push(CARTR);
                        }
                        index++;
                    }
                } else {

                    if (INDEX != -1) {
                        this.CARTS.splice(INDEX, 1);
                    }
                }
                // this.$cookies.set('carts',this.CARTS);
                localStorage.setItem('carts', JSON.stringify(this.CARTS));
                this.$notify({
                    title: "Producto removido del carrito",
                    // text: `El producto '${CART.title}' se elimino del carrito correctamente!`,
                    type: 'error',
                    duration: 4500,
                });
                this.emitter.emit('cart-shop', this.CARTS);
            } else {
                if (INDEX != -1) {
                    this.deleteCart(this.CARTS[INDEX], INDEX);
                }
            }
        },
        async deleteCart(data, index) {
            try {
                const resp = await myApi.post(
                    "ecommerce/carts/delete/" + data.cart_id,
                    {
                        CARTS: this.CARTS,
                    }
                );
                console.log(resp);

                if (resp.data.message == 203) {
                    this.emitter.emit("cart-shop", resp.data.CARTS);
                } else {
                    if (index != -1) {
                        this.CARTS.splice(index, 1);
                    }
                    this.emitter.emit('cart-shop', this.CARTS);
                    this.$notify({
                        title: "Producto removido del carrito",
                        // text: `TU PRODUCTO '${data.title}' SE ELIMINO DEL CARRITO!`,
                        type: 'error',
                        duration: 1000,
                    });
                }

            } catch (error) {
                console.log(error);
            }
        },
        async applyCupon() {
            let data = {
                code_cupon: this.code_cupon,
                CARTS: this.CARTS
            }
            const resp = await myApi.post('ecommerce/apply_coupon', data);
            if (resp.data.error) {
                for (let key in resp.data.error) {
                    this.$notify({
                        title: "VALIDACIÓN",
                        text: resp.data.error[key],
                        duration: 1000,
                        type: 'error',
                    });
                }
            } else {
                console.log(resp);
                if (resp.data.message == 403) {
                    this.$notify({
                        // title: "CUPON",
                        title: resp.data.message_text,
                        type: 'error',
                        duration: 4500,
                    });
                } else {
                    console.log(resp);
                    if (!store.getters.loggedIn) {
                        // this.$cookies.set('carts',resp.data.CARTS);
                        localStorage.setItem('carts', JSON.stringify(resp.data.CARTS));
                    }
                    let APPLY_C = resp.data.CARTS.find(item => item.code_cupon == this.code_cupon && !item.code_discount)
                    if (APPLY_C) {
                        this.groups_coupons.push(this.code_cupon);
                    }
                    this.code_cupon = null;
                    this.$notify({
                        title: "EL CUPON SE APLICO CORRECTAMENTE",
                        // text: ``,
                        duration: 4500,
                    });
                    this.emitter.emit("cart-shop", resp.data.CARTS);
                }
            }
        },
        removeCoupon(groups_coup) {
            let INDEX = this.groups_coupons.findIndex(item => item == groups_coup);
            if (INDEX != -1) {
                this.groups_coupons.splice(INDEX, 1);
            }
            CartResource.deleteCoupon(this.CARTS, groups_coup, this.emitter, this.$notify, this.$cookies)
        },
        isNotStockProduct(CART) {
            let PROCESS_SUCURSAL = this.products_process_sucursals.find(item => item.uniqd == CART.uniqd);
            if (PROCESS_SUCURSAL) {
                return "Producto fuera de stock por el momento";//"Solo hay "+ PROCESS_SUCURSAL.quantity_sucursal + (PROCESS_SUCURSAL.quantity_sucursal > 1 ? " unidades " : " unidad ")  +"de este producto disponible";
            }
            return false;
        },
        async processCartSucursal() {
            try {
                this.loadingSucursal = false;
                this.products_process_sucursals = [];
                const resp = await myApi.post('ecommerce/process_cart_sucursal', {
                    CARTS: this.CARTS,
                });


                this.loadingSucursal = true;
                if (resp.data.message == 500) {
                    this.products_process_sucursals = resp.data.products;
                }


                let loginUSer=localStorage.getItem('user')
                let tmpProd = localStorage.getItem('carts') ? JSON.parse(localStorage.getItem('carts')) : [];
                if(tmpProd.length==0){
                    tmpProd=loginUSer==null ? [] :this.CARTS
                }
                let newPriceLocal = []

                console.log('datos temporales')
                console.log(tmpProd)

                this.CARTS = this.CARTS.map((cart) => {
                    // console.log(this.isNotStockProduct(cart));
                    cart.is_not_stock_product = this.isNotStockProduct(cart);
                    console.log('nuevo precio')
                    console.log(resp.data.prrecios)
                    if (resp.data.prrecios) {
                        let precio = resp.data.prrecios.filter((item) => String(item.id_prod) == String(cart.uniqd))
                      
                        if (precio.length > 0) {
                            let dataTmp = JSON.parse(JSON.stringify(tmpProd)).filter((itemProd) => itemProd.uniqd == precio[0].id_prod)[0]
                            console.log(dataTmp)
                            
                            if (dataTmp) {
                                if (precio[0].descuneto) {
                                    dataTmp.type_discount = 1
                                    dataTmp.type_discount_old = 1
                                    dataTmp.price_unit = precio[0].precio
                                    dataTmp.discount = precio[0].descuneto.percentage==null ? 0 : precio[0].descuneto.percentage
                                    dataTmp.discount_old = precio[0].descuneto.percentage
                                    dataTmp.code_discount = precio[0].descuneto.discount.uniqd
                                    dataTmp.code_offer = precio[0].descuneto.discount.uniqd
                                    dataTmp.subtotal = precio[0].descuneto.new_amount
                                } else {
                                    dataTmp.type_discount = 0
                                    dataTmp.type_discount_old = 0
                                    dataTmp.discount = 0
                                    dataTmp.discount_old = 0
                                    dataTmp.code_discount = 0
                                    dataTmp.code_offer = 0
                                    dataTmp.price_unit = precio[0].precio
                                    dataTmp.subtotal = precio[0].precio
                                }
                                dataTmp.total = dataTmp.quantity * dataTmp.subtotal
                                newPriceLocal.push(dataTmp)

                            }

                        }



                    }


                    return cart;
                })
                this.CARTS = newPriceLocal

                if(loginUSer==null){
                    localStorage.setItem('carts', JSON.stringify(newPriceLocal))
                }else{
                    myApi.post("ecommerce/update_cart", {
                    cart:newPriceLocal
                    })
                    .then(()=>{
                        this.emitter.emit('refres-cart-list', {})
                    })
                    
                }
                
                //this.emitter.emit('cart-shop', newPriceLocal)

                this.DESCUENTO_TOTAL_SUM = 0;
                this.SUB_TOTAL_SUM = 0;
                this.TOTAL_SUM = this.CARTS.reduce((sum, cart) => sum + parseFloat(cart.total), 0);

                this.CARTS.forEach((cart) => {
                    if (cart.discount > 0) {
                        this.DESCUENTO_TOTAL_SUM += (cart.price_unit - cart.subtotal);
                    }
                    this.SUB_TOTAL_SUM += (cart.price_unit * cart.quantity)
                });
                setTimeout(() => {
                    const script2 = document.createElement('script');
                    script2.src = '../../assets/js/feather/feather.min.js';
                    document.body.appendChild(script2);
                    script2.onload = () => {
                        console.log('archivo.js cargado 2');
                    }
                }, 50);

                this.loader = false
            } catch (error) {
                console.log(error);
                this.$notify({
                    title: "Hubo un error a nivel interno, comuniquese con el soporte de la pagina",
                    duration: 3000,
                    type: 'error',
                });
            }
        },

        initCarst() {
            let CARTS = localStorage.getItem('carts') ? JSON.parse(localStorage.getItem('carts')) : [];//this.$cookies.get("carts") ? this.$cookies.get("carts") : [];
            this.CARTS = CARTS;
        }
    },
    created() {
        this.sin_stock = this.$route.query.sin_stock;
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.initCarst();
        });
    },
    mounted() {
        this.useMetaT = useMeta({
            title: 'Mi Carrito',
            htmlAttrs: { lang: 'en', amp: true },
            meta: [],
        })
        let CARTS = localStorage.getItem('carts') ? JSON.parse(localStorage.getItem('carts')) : [];//this.$cookies.get("carts") ? this.$cookies.get("carts") : [];
        this.CARTS = CARTS;
        console.log('listado del carrito')
        console.log(this.CARTS)
        this.TOTAL_SUM = this.CARTS.reduce((sum, cart) => sum + parseFloat(cart.total), 0);

        this.emitter.on('cart-shop', data => {
            this.CARTS = data;
            //  if(this.sin_stock){
            this.processCartSucursal();
            //  }
            //  this.TOTAL_SUM = this.CARTS.reduce((sum,cart) => sum+ parseFloat(cart.total),0);
            if (this.groups_coupons.length == 0) {
                let F_CARTS = this.CARTS.filter(item => item.code_cupon && !item.code_discount);
                if (F_CARTS.length > 0) {
                    F_CARTS.forEach(element => {
                        let INDEX = this.groups_coupons.findIndex((code_cupon) => code_cupon == element.code_cupon);
                        if (INDEX == -1) {
                            this.groups_coupons.push(element.code_cupon);
                        }
                    });
                }
            }
            const script2 = document.createElement('script');
            script2.src = '../../assets/js/feather/feather.min.js';
            document.body.appendChild(script2);
            script2.onload = () => {
                console.log('archivo.js cargado 2');
            }
        });
        this.emitter.on('groups_coupons', data => {
            console.log(data);
            this.groups_coupons = data;
        });

        this.emitter.on('add-error-cart-shop', data => {
            console.log(data);
            this.$notify({
                title: data,
                duration: 4500,
                type: 'error',
            });
        });

        this.emitter.on('acept-terminos', data => {
            console.log(data);
            this.removeCart(this.CART_SELECTED);
        });

        this.emitter.emit("cart-list", {});
    }
}
</script>