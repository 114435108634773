import axios from 'axios'
import store from './store'

var myApi = axios.create({
    baseURL: process.env.VUE_APP_URL_BACKEND+'api/',
    // timeout: 1000,http://localhost:8000/
    headers: {'X-Custom-Header': 'CustomHeader1','Authorization': store.state.token ? 'Bearer '+ store.state.token : ''}
});


myApi.interceptors.response.use(
    (response) => {
        if(response.data?.status=="Token is Invalid"){
            localStorage.clear()
            sessionStorage.clear()
            window.location.replace('/')
        }

        return response;
    },
    (error) => {
        console.log('⚠️ Error en respuesta: ', error);
        return Promise.reject(error);
    }
);

export default myApi