<template>
  <notifications class="my-5" />
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? ` ${content}` : `ZONA DIGITAL` }}</template>
  </metainfo>
  <HeaderSection v-if="isRouterAuth" />
  <router-view></router-view>
  <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="pswp__bg"></div>
    <div class="pswp__scroll-wrap">
      <div class="pswp__container">
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
      </div>
      <div class="pswp__ui pswp__ui--hidden">
        <div class="pswp__top-bar">
          <div class="pswp__counter"></div>

          <button class="pswp__button pswp__button--close" aria-label="Close (Esc)"></button>
          <button class="pswp__button pswp__button--zoom" aria-label="Zoom in/out"></button>

          <div class="pswp__preloader">
            <div class="loading-spin"></div>
          </div>
        </div>

        <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
          <div class="pswp__share-tooltip"></div>
        </div>

        <button class="pswp__button--arrow--left" aria-label="Previous (arrow left)">
          ❮
        </button>
        <button class="pswp__button--arrow--right" aria-label="Next (arrow right)">
          ❯
        </button>

        <div class="pswp__caption">
          <div class="pswp__caption__center"></div>
        </div>
      </div>
    </div>
  </div>
  <FooterSection v-if="isRouterAuth" />
  <ReviewProduct />
</template>

<script>
import HeaderSection from './components/layouts/HeaderSection.vue'
import FooterSection from './components/layouts/FooterSection.vue'
// import mitt from 'mitt'
// const emitter = mitt()
import ReviewProduct from './views/guest/modal/ReviewProduct.vue'
import myApi from './plugins/axios'
// import BlogJson from './views/guest/blog/Blog.json'

export default {
  name: 'App',
  components: {
    HeaderSection,
    FooterSection,
    ReviewProduct,
  },
  data() {
    return {
      isRouterAuth: true,
      response_review: true,
    }
  },
  // mounted() {
  // const script = document.createElement('script');
  //   script.src = '../../assets/js/bootstrap/bootstrap.bundle.min.js';
  //   document.body.appendChild(script);
  // script.onload = () => {
  //   console.log('archivo.js cargado 1');
  // }
  // const script2 = document.createElement('script');
  //   script2.src = '../../assets/js/feather/feather.min.js';
  //   document.body.appendChild(script2);
  // script2.onload = () => {
  //   console.log('archivo.js cargado 2');
  // }
  // const script3 = document.createElement('script');
  //   script3.src = '../../assets/js/swiper-slider/swiper-bundle.min.js';
  //   document.body.appendChild(script3);
  //   script3.onload = () => {
  //   console.log('archivo.js cargado 3');
  // }
  // const script4 = document.createElement('script');
  //   script4.src = '../../assets/js/swiper-slider/swiper-custom.min.js';
  //   document.body.appendChild(script4);
  // script4.onload = () => {
  //   console.log('archivo.js cargado 4');
  // }
  // const script5 = document.createElement('script');
  //   script5.src = '../../assets/js/timer.js';
  //   document.body.appendChild(script5);
  // script5.onload = () => {
  //   console.log('archivo.js cargado 5');
  // }
  // const script6 = document.createElement('script');
  //   script6.src = '../../assets/js/sticky-header.js';
  //   document.body.appendChild(script6);
  // script6.onload = () => {
  //   console.log('archivo.js cargado 6');
  // }
  // const script7 = document.createElement('script');
  //   script7.src = '../../assets/js/active-class.js';
  //   document.body.appendChild(script7);
  // script7.onload = () => {
  //   console.log('archivo.js cargado 7');
  // }
  // const script8 = document.createElement('script');
  //   script8.src = '../../assets/js/wow.js';
  //   document.body.appendChild(script8);
  //   script8.onload = () => {
  //   console.log('archivo.js cargado 8');
  // }
  // const script9 = document.createElement('script');
  //   script9.src = '../../assets/js/wow-custom.js';
  //   document.body.appendChild(script9);
  // script9.onload = () => {
  //   console.log('archivo.js cargado 9 ');
  // }
  // const script10 = document.createElement('script');
  //   script10.src = '../../assets/js/script.js';
  //   document.body.appendChild(script10);
  // script10.onload = () => {
  //   console.log('archivo.js cargado 10');
  // }

  // },
  updated() {
  },
  created() {
    this.scrollTop();
  },
  methods: {
    deleteCookiesCache() {
      // this.$cookies.remove("banners_img_b");

      this.$store.dispatch('deleteDat', 'ecommerce');
      // this.$cookies.remove("marca_descatado_i");
      // this.$cookies.remove("categories_i");
      window.indexedDB.deleteDatabase("ecommerce_db");

      this.$cookies.remove("config_general_img_b");
      // this.$cookies.remove("videos_img_b");
      this.$cookies.remove("config_general");

      this.$cookies.remove("categories");
      this.$cookies.remove("cintillo_b");

      localStorage.removeItem("departaments");
      let local_dinamic = localStorage.getItem("local_dinamic") || JSON.stringify([]);
      local_dinamic = JSON.parse(local_dinamic);
      if (local_dinamic.length > 0) {
        local_dinamic.forEach((item) => {
          localStorage.removeItem(item);
        })
        localStorage.removeItem("local_dinamic");
      }
    },
    actualizarMetaetiquetas(titulo, descripcion, imagen, url) {
      const ogTitle = document.querySelector('meta[property="og:title"]');
      const ogDescription = document.querySelector('meta[property="og:description"]');
      const ogImage = document.querySelector('meta[property="og:image"]');
      const ogUrl = document.querySelector('meta[property="og:url"]');

      ogTitle.content = titulo;
      ogDescription.content = descripcion;
      ogImage.content = imagen;
      ogUrl.content = url;
    },
    scrollTop() {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 50);
    },
  },
  async mounted() {
    // emitter.on('cart-shop',data => {
    //     console.log(data);
    // });
    // this.emitter.on('review-modal-refresh',() => {
    //   this.response_review = false;
    //   setTimeout(() => {
    //     this.response_review = true;
    //   }, 50);
    // });
    this.$router.beforeEach((to, from, next) => {
      // console.log('Cambiaste de ruta', to, from.path);
      // if(to.path.indexOf('/blog/') != -1){
      //   let blog_slug = to.params.slug;
      //   let BLOG_S = BlogJson.find((item) => item.slug == blog_slug);
      //   if(BLOG_S){
      //       this.actualizarMetaetiquetas(BLOG_S.title,BLOG_S.description,BLOG_S.image,BLOG_S.url);
      //   }
      // }
      if (to.path == '/registro' || to.path == '/login' || to.path == '/recuperar-credenciales' || to.path == '/codigo-de-recuperacion'
        || to.path == '/reset-credenciales' || to.path == '/checkout') {
        this.isRouterAuth = false;
      } else {
        this.isRouterAuth = true;
      }
      const script2 = document.createElement('script');
      script2.src = '../../assets/js/feather/feather.min.js';
      document.body.appendChild(script2);
      script2.onload = () => {
        console.log('archivo.js cargado 2');
      }
      const script4 = document.createElement('script');
      script4.src = '../../assets/js/swiper-slider/swiper-custom.min.js';
      document.body.appendChild(script4);
      script4.onload = () => {
        console.log('archivo.js cargado 4');
      }
      // const script5 = document.createElement('script');
      // script5.src = '../../assets/js/timer.js';
      // document.body.appendChild(script5);
      // script5.onload = () => {
      // console.log('archivo.js cargado 5');
      // }
      if (this.isRouterAuth) {
        const script6 = document.createElement('script');
        script6.src = '../../assets/js/sticky-header.js';
        document.body.appendChild(script6);
        script6.onload = () => {
          console.log('archivo.js cargado 6');
        }
      }
      const script7 = document.createElement('script');
      script7.src = '../../assets/js/active-class.js';
      document.body.appendChild(script7);
      script7.onload = () => {
        console.log('archivo.js cargado 7');
      }
      const script9 = document.createElement('script');
      script9.src = '../../assets/js/wow-custom.js';
      document.body.appendChild(script9);
      script9.onload = () => {
        console.log('archivo.js cargado 9 ');
      }
      var script10 = document.createElement('script');
      script10.src = '../../assets/js/script.js';
      document.body.appendChild(script10);
      script10.onload = () => {
        console.log('archivo.js cargado 10');
      }

      if (!document.querySelector('script[src="../../assets/js/darkWhite.js"]')) {
        var script1T = document.createElement('script');
        script1T.src = '../../assets/js/darkWhite.js';
        document.body.appendChild(script1T);
        script1T.onload = () => {
          console.log('archivo.js cargado 1T');
        }
      }


      setTimeout(() => {
        this.scrollTop();
      }, 50);
      next()
    })

    let CACHE_CLEAR = this.$cookies.get("cache_clean") ? this.$cookies.get("cache_clean") : null;
    // this.$store.dispatch('deleteDat','ecommerce');
    // console.log(CACHE_CLEAR);
    if (!CACHE_CLEAR) {
      let resp = await myApi.get('ecommerce/get_cache_status');
      console.log(resp);
      if (resp.data.cache_clean && resp.data.cache_clean.is_clean) {
        this.$cookies.set('cache_clean', resp.data.cache_clean);
        setTimeout(() => {
          this.deleteCookiesCache();
        }, 300);
      }
    }

    let reset_info = localStorage.getItem("reset_info") || null;
    if (!reset_info) {
      localStorage.setItem('reset_info', new Date().getTime() + 3600000 * 24);
    } else {
      const now = new Date()
      if (now.getTime() > reset_info) {
        setTimeout(() => {
          this.deleteCookiesCache();
          localStorage.removeItem("reset_info")
        }, 300);
      }
    }

  },
}
</script>

<style>
.vue-notification-group {
  top: 125px !important;
}

.vue-notification {
  margin: 0 5px 5px;
  padding: 10px;
  font-size: 12px;
  color: #fff;
  background: #44a4fc;
  border-left: 5px solid #187fe7;
}

.vue-notification.success {
  background: #0d6efd !important;
  border-left-color: #507cbd !important;
}

.vue-notification.warn {
  background: #ffb648;
  border-left-color: #f48a06;
}

.vue-notification.error {
  background: #e54d42;
  border-left-color: #b82e24;
}
</style>
<style>
/* Loader 5 */
.loader-5 {
  height: 32px;
  width: 32px;
  -webkit-animation: loader-5-1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  animation: loader-5-1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@-webkit-keyframes loader-5-1 {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loader-5-1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-5::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: auto;
  margin: auto;
  width: 8px;
  height: 8px;
  background: #5c77fc;
  border-radius: 50%;
  -webkit-animation: loader-5-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  animation: loader-5-2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@-webkit-keyframes loader-5-2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }

  50% {
    -webkit-transform: translate3d(24px, 0, 0) scale(.5);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes loader-5-2 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }

  50% {
    transform: translate3d(24px, 0, 0) scale(.5);
  }

  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

.loader-5::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: auto;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  background: #5c77fc;
  border-radius: 50%;
  -webkit-animation: loader-5-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  animation: loader-5-3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@-webkit-keyframes loader-5-3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }

  50% {
    -webkit-transform: translate3d(-24px, 0, 0) scale(.5);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes loader-5-3 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }

  50% {
    transform: translate3d(-24px, 0, 0) scale(.5);
  }

  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

.loader-5 span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
}

.loader-5 span::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: auto;
  right: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  background: #5c77fc;
  border-radius: 50%;
  -webkit-animation: loader-5-4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  animation: loader-5-4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@-webkit-keyframes loader-5-4 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }

  50% {
    -webkit-transform: translate3d(0, 24px, 0) scale(.5);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes loader-5-4 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }

  50% {
    transform: translate3d(0, 24px, 0) scale(.5);
  }

  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

.loader-5 span::after {
  content: "";
  display: block;
  position: absolute;
  top: auto;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  background: #5c77fc;
  border-radius: 50%;
  -webkit-animation: loader-5-5 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
  animation: loader-5-5 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@-webkit-keyframes loader-5-5 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }

  50% {
    -webkit-transform: translate3d(0, -24px, 0) scale(.5);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes loader-5-5 {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
  }

  50% {
    transform: translate3d(0, -24px, 0) scale(.5);
  }

  100% {
    transform: translate3d(0, 0, 0) scale(1);
  }
}
</style>
<style>
.vue-notification-template {}

.vue-notification {
  /* display: block;
  box-sizing: border-box;
  text-align: left; */
  font-size: 0.95rem !important;
  /* padding: 10px; */
  /* margin: 0 5px 5px;
  color: #fff;
  background: #44A4FC;
  border-left: 5px solid #187FE7; */
}

.notification-title {
  font-weight: 600;
}

.vue-notification.warn {
  background: #ffb648;
  border-left-color: #f48a06;
}
</style>